@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
    --primary-color: #066769;
    --primary-color-light: #008080;
    --neutral-1000: #121212;
    --neutral-700: #4C5858;
    --neutral-600: #5D6C6C;
    --neutral-00: #FAFAFA;
    --neutral-950: #262B2B;
    --error-color: #A62F2F;
    --success-color: #46A14B;
    --background-light: #F7F8F8;
}

* {
    margin: 0;
    padding: 0;
    font-size: 12px;
    font-family: "Poppins", sans-serif;
}

.otpPage {
    background-color: var(--neutral-00);
    display: flex;
    align-items: center;
    height: 100vh;
}

.otpleft {
    display: flex;
    width: 50%;
    align-items: center;
}

.otpleftSub {
    margin-left: 20%;
    width: 347px;
}

#otpp {
    margin-left: 6.5px;
    font-size: 14px;
    margin-bottom: 48px;
    color: #000;
    white-space: wrap;
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
}

#otph2 {
    margin-left: 6.5px;
    font-size: 24px;
    margin-bottom: 8px;
    color: var(--neutral-1000);
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    line-height: 31.2px;
}

#digitbox {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

#otpsubmit {
    width: -webkit-fill-available;
    width: -moz-available;
    padding: 10px 24px;
    border-radius: 8px;
    border: none;
    color: white;
    background: var(--primary-color);
}

.otpright {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background: var(--background-light);
}

.otpcontent img {
    width: 434px;
    height: 404px;
}

.digits {
    display: flex;
    justify-content: space-between;
    margin-bottom: 48px;
}

.digit {
    width: 45px;
    height: 45px;
    border-radius: 8px;
    border: 1px solid var(--neutral-600);
    background: var(--neutral-00);
}

#digit1, #digit2, #digit3, #digit4, #digit5, #digit6 {
    text-align: center;
    padding: 0;
    width: 45px;
    height: 45px;
    border-radius: 8px;
    border: 1px solid var(--neutral-600);
    background: var(--neutral-00);
    color: var(--neutral-950);
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 25.2px;
}

#resend {
    color: var(--neutral-600);
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    margin-bottom: 16px;
}

#digit1:focus, #digit2:focus, #digit3:focus, #digit4:focus, #digit5:focus, #digit6:focus {
    outline: none;
    border-radius: 8px;
    border: 2px solid var(--success-color);
    background: var(--neutral-00);
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

span {
    font-size: 14px;
}

input.error {
    border: 2px solid var(--error-color) !important;
}

input.success {
    border: 2px solid var(--success-color) !important;
}

@media (min-width: 921px) {
    #otpmobscreenlogo {
        display: none;
    }
}

@media (max-width: 1020px) {
    .otpright {
        width: 0%;
        display: none;
    }

    .otpleft {
        padding-top: 5vh;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
    }

    .otpleftSub {
        height: 100%;
        margin-left: 0%;
    }

    #otph2 {
        top: 0;
        margin-top: 4vh;
        font-size: 26px;
    }

    #otpmobscreenlogo {
        height: 40vh;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #otpmobscreenlogo img {
        height: fit-content;
    }

    .digits {
        display: flex;
        flex-direction: column;
    }

    #digitbox {
        display: flex;
        justify-content: space-between;
    }

    .askSign > p {
        font-size: 14px;
    }

    .askSign a {
        font-size: 14px;
    }
}

@media (max-width: 500px) {
    .otpleft {
        width: 90%;
        margin-left: 5%;
        margin-right: 5%;
    }
}
