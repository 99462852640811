@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


:root {
    --color-error: #A62F2F;
    --color-primary:  #066769;
    --color-primary-light:   #008080;
    --color-neutral-1000:  #121212;
    --color-neutral-800:  #414A4B;
    --color-neutral-700: #4C5858;
    --color-neutral-300: #ADB8B8;
    --color-neutral-100:  #EEF0F0;
  
    --color-neutral-00: #FAFAFA;
  
    --color-background-light:#F7F8F8;
  
    --color-toast-error-bg: #FBE5E5;
  
    --color-toast-success-bg:  #E3F5E3;
    
    --color-toast-success-border:#55b938;
    
    --color-toast-error-border: var(--color-error);
}

*{
    margin: 0;
    padding: 0;
  font-size: 12px;
  font-family: "Poppins", sans-serif;

}
#mobscreenlogo{
  display: none; 
  position: absolute; 
  top: 0;
  left: 0;
  width: 100%; 
  z-index: 55; 
}
:root{
    --toastify-color-error:var(--color-error);
}
.loginPage{
    background-color: #fff;


  display: flex;
  align-items: center;
  height: 100vh;
}
.left{
    
   display: flex;
   width: 50%;
  
   align-items: center;
}
#mobscreenlogo img{
display: none;
}
.leftSub{
    margin-left: 20%;
width: 347px;
}
.leftSub p{
    font-size: 14px;
    margin-bottom: 39px;
    color: #000;


font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 21px; 
}
#leftsubh2{
    
    font-size: 24px;
    margin-bottom: 8px;
    color: var(--Neutral-1000,  var( --color-neutral-1000));


font-family: Poppins;
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: 31.2px;
}
input[type="email"],input[type="password"]{
      padding: 20px;
      width: -webkit-fill-available;
      width: -moz-available;
      border-radius: 8px;
     
      border: none;
      border: 1px solid var(--Neutral-700, var(--color-neutral-700));
}
#pass{
  margin-bottom: 30px;
}
#forgethover:hover{
    color:   var(--color-primary-light);
}
input[type="checkbox"]{

    justify-self: flex-end;
    
    margin-right: 7px;
    margin-left: auto;
}
input[type="submit"]{
    width: -webkit-fill-available;
    width: -moz-available;
    padding: 10px 24px;
    border-radius: 8px;
    border: none;
    color: white;
    background: var(--Primary-800,  var( --color-primary));
}
.checkbox-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 40px;

    
}
#terms a{
  margin-left: 5px;
  margin-right: 5px;
}
.checkbox-container a{
    white-space: nowrap;
    font-size: 13px;
    justify-self: flex-start;
    color: var(--Neutral-1000,  var( --color-neutral-1000));


font-family: Poppins;

font-style: normal;
font-weight: 400;
line-height: 21px;

}
.checkbox-container label{
    white-space: nowrap;
    font-size: 13px;

}
.askSign{
    margin-top: 16px;
    text-align: center;
}
.askSign a{
    color: var(--Primary-800,  var( --color-primary));
    color: var(--Primary-700,   var(--color-primary-light));


font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 20px; 
}   
.right{
   
   width: 50%;
   height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
background:   var(--color-background-light);
}   
.content h1{
    color: var(--Neutral-1000,  var( --color-neutral-1000));
text-align: center;


font-family: Poppins;
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: 31.2px;
padding-bottom: 8px;
}
.content p{
    color: var(--Neutral-800,    var(--color-neutral-800)
);


font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 22.4px; 
}
.content img{
    width: 70%;

}

.rec2{
    
    width: 15px;
height: 8px;
border-radius: 10px;
background: var(--Neutral-300,  var(--color-neutral-300));
}

.scroller{
    display: flex;
    gap: 8px;
    

    position: absolute;
    bottom: 10vh;
    right: 22vw;
}
input[type="submit"]{
    color: var(--Neutral-00,  var( --color-neutral-00));

font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 20px;
}
input[type="checkbox"] {
    appearance: none;
    width: 18px;
height: 18px;
border-radius: 4px;
background: var(--Neutral-100,  var(--color-neutral-100));
    position: relative;
    cursor: pointer;
    margin-right: 7px;
}
input[type="checkbox"]:checked::before {
    content: '✔';
    font-size: 12px;
    color: rgb(255, 255, 255);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
input[type="checkbox"]:checked {
    background-color: var(--Primary-800,  var( --color-primary));
    border-color: var(--Primary-800,  var( --color-primary));
}
::placeholder{
    color: var(--Neutral-1000,  var( --color-neutral-1000));

font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 25.6px;
}





  .scroller {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  
  .rec {
    width: 10px;
    height: 10px;
    background-color: gray;

   
    transition: width 0.3s ease, background-color 0.3s ease; 
  }
  
  .recActive {
    border-radius: 10px;
    background: var(--Primary-700,   var(--color-primary-light));
    width: 30px;
height: 8px;
  }
  
  .rec2 {
    background-color: lightgray;
    width: 10px; 
  }
  
  .slider {
    position: relative;
    overflow: hidden;
    width: 70%; 
    margin: 0 auto; 
}

.slides {
    display: flex;
    transition: transform 0.5s ease-in-out;
}

.slide {
    min-width: 100%; 
    transition: opacity 0.5s ease-in-out;
}
input[type="text"]:focus,input[type="email"]:focus,input[type="password"]:focus{
    outline: none;
    border: 2px solid  var( --color-primary);
}
input[type="email"],input[type="password"],input[type="text"]{
    color: var(--Neutral-1000,  var( --color-neutral-1000));

  
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25.6px; 
}
.leftSub .error-message {
    color: var(--color-error);
    font-size: 0.9em;
    margin-top: 5px;
    margin-left: 16px;
    margin-bottom: 8px !important;
    
  }
  
  .leftSub .textinput.input-error {
    border-color: var(--color-error);
    border-width: 2px; 
  }
  
  .no-margin {
    margin-bottom: 0!important;
    border: 2px solid var(--color-error) !important;
  }
  .password-input-container {
    position: relative;
    width: -webkit-fill-available;
    width: -moz-available;
  }
  
  .password-input {
    width: 100%;
    padding-right: 40px; 
  }
  
  .toggle-password-btn {
    position: absolute;
    top: 38%;
    right: 20px;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.2em;
  }
  
  .custom-toast {
    background-color:   var(--color-toast-error-bg) !important;
    color: black;          
    border-radius: 0px;               
    padding: 10px;   
    border-left: 5px solid var(--color-error);
                
     
  }
  .input-container {
    position: relative;
    width: 100%;
  
  }
  
  .textinput {
    width: 100%;
    padding: 20px 16px 8px; 
    font-size: 16px;
    border: 1px solid var(--Neutral-700, var(--color-neutral-700));
    border-radius: 8px;
  }
  
  .label {
    position: absolute;
    top: 37%;
    left: 16px;
    color: var(--Neutral-700, var(--color-neutral-700));
    font-size: 16px;
    pointer-events: none;
    transform: translateY(-50%);
    transition: 0.3s ease;
padding-left: 5px;
padding-right: 5px;
  }
  
  .textinput:focus + .label,
  .textinput:not(:placeholder-shown) + .label {
    background-color: #fff;
    text-align: center;
   
    top: 0px;
    left: 10px;
    font-size: 12px;
    color: var(--Primary-800,  var( --color-primary));
  }
  .input-error{
    color: var(--color-error) !important;
    ;
  }
  
  .custom-toastS{
    background-color:   var(--color-toast-success-bg) !important;
    color: black;          
    border-radius: 0px;               
    padding: 10px;   
    border-left: 5px solid var(--color-toast-success-border);
   }
  
   .loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  #Fp:hover{
    color:  var( --color-primary) ;
  }
  label {
    user-select: none; 
  }
  
  .moving-circle {
    width: 50px;
    height: 50px;
    border: 5px solid #fff;
    border-top: 5px solid  var( --color-primary);
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .disabled-button {
    opacity: 0.6;
    cursor: not-allowed;
  }
  
 @media (max-width:600px) {
  .right{
    display:none;
  }
  .left{
    width: 100%;
  }
  .leftSub{
    margin-left: 5%;
    width: 90%;
    margin-right: 5%;
  }
  #leftSubh2{
    font-size: 30px;
  }
  .leftSub p{
   
   font-size: 14px;
   margin-right: 5%;
  }
  .input-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}
.checkbox-container{
  margin-bottom: 50px;
  
}
.checkbox-container a{
  font-size: 14px;
  justify-self: left;
}
input[type="checkbox"]{
  margin-left: auto;
  
}
#mobscreenlogo {
  display: flex; 
  justify-content: center;
 align-items: center;
 margin-top: 4vh;
}
#mobscreenlogo img{
 display: block;
}
.askSign{
  display: flex;
    justify-content: center;
}
.askSign> p{
  display: flex;
  align-self: center;
}
.askSign a{
  margin-left: 10px;
  font-size: 20px;
}
}
@media (max-width:421px) {
  .right{
    display:none;
  }
  .left{
    width: 100%;
  }
  .leftSub{
    margin-left: 5%;
    width: 90%;
    margin-right: 5%;
  }
  #leftSubh2{
    font-size: 30px;
  }
  .leftSub p{
   font-size: 14px;
   margin-right: 5%;
  }
  .input-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}
.checkbox-container{
  margin-bottom: 50px;

}
.checkbox-container a{
  font-size: 14px;
  justify-self: left;
}
input[type="checkbox"]{
  margin-left: auto;
  
}
#mobscreenlogo {
  display: flex; 
  justify-content: center;
 align-items: center;
 margin-top: 4vh;
}
#mobscreenlogo img{
 display: block;
}
.askSign{
  display: flex;
    justify-content: center;
}
.askSign> p{
  display: flex;
  align-self: center;
}
.askSign a{
  margin-left: 10px;
  font-size: 20px;
}
}
@media (max-width:350px) {
  .askSign a{
    font-size: 14px;
  }
  .askSign> p{
    font-size: 14px;
  }
}
@media(min-width:600px) and (max-width:1020px) {
  .right{
    display:none;
  }
  .left{
    width: 100%;
  }
  .leftSub{
    margin-left: 20%;
    width: 60%;
    margin-right: 20%;
  }
  #leftSubh2{
    font-size: 30px;
  }
  .leftSub p{
   font-size: 14px;
   margin-right: 5%;
  }
  .input-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}
.checkbox-container{
  margin-bottom: 50px;

}
.checkbox-container a{
  font-size: 14px;
  justify-self: left;
}
input[type="checkbox"]{
  margin-left: auto;

}
#mobscreenlogo {
  display: flex; 
  justify-content: center;
 align-items: center;
 margin-top: 4vh;
}
#mobscreenlogo img{
 display: block;
}
.askSign{
  display: flex;
    justify-content: center;
}
.askSign> p{
  display: flex;
  align-self: center;
}
.askSign a{
  margin-left: 10px;
  font-size: 20px;
}
}
@media(max-width:1050px){
  .passIn{
    flex-direction: column;
  }
  .leftSub .error-message{
    margin-top: 0;
    align-self: flex-start;
  }
}
@media (max-height:650px){
  .leftSub p{
    margin-bottom: 10px;
  }
}

 
  

#searchClass{
    background-color:   var(--color-background-light) ;
    border: none;

    font-size: 14px;

}
#searchClass::placeholder{
    color: var(--color-neutral-700);
    font-size: 12px;
}


/* Add this CSS to your stylesheet */
.placeholder-black::placeholder {
  color: black;
}