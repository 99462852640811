@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
    --primary-color: #066769;
    --primary-color-light: #008080;
    --neutral-1000: #121212;
    --neutral-700: #4C5858;
    --neutral-600: #5D6C6C;
    --neutral-00: #FAFAFA;
    --neutral-950: #262B2B;
    --background-light: #F7F8F8;
    --text-dark: #394141;
    --green-color: green;
}

#newpasspage {
    background-color: var(--neutral-00);
    display: flex;
    align-items: center;
    height: 100vh;
    width: 100vw;
}

#newpassleft {
    width: 50%;
    display: flex;
    align-items: center;
    height: 100vh;
}

#newpassleftsub {
    margin-left: 20%;
    width: 347px;
}

#newpassh2 {
    font-size: 24px;
    margin-bottom: 10px;
    color: var(--neutral-1000);
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    line-height: 31.2px;
}

#newpassp {
    font-size: 14px;
    margin-bottom: 32px;
    color: var(--text-dark);
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
}

#newpasssubmit {
    width: -webkit-fill-available;
    padding: 10px 24px;
    border-radius: 8px;
    border: none;
    color: white;
    margin-top: 19px;
    background: var(--primary-color);
}

.newpassright {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background: var(--background-light);
}

.newpasscontent img {
    width: 434px;
    height: 404px;
}

@media (min-width: 921px) {
    #newmobscreenlogo {
        display: none;
    }
}

@media (max-width: 920px) {
    #newpassleft {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
    }

    .newpassright {
        width: 0%;
        display: none;
    }

    #newpassleftsub {
        height: 100%;
        margin-left: 0%;
    }

    #newpassh2 {
        top: 0;
        margin-top: 4vh;
        font-size: 26px;
    }

    #newmobscreenlogo {
        height: 40vh;
        width: 100%;
        display: flex;
        justify-content: center;
        align-content: center;
        margin-bottom: 4vh;
    }

    #rnewpassmobscreenlogo img {
        height: fit-content;
        max-width: 90%;
    }

    #newpasssubmit {
        margin-bottom: 50px;
    }
}

.password-popup2 p.met {
    color: var(--green-color);
}

.password-popup2 p {
    font-size: 14px;
}

@media (max-width: 400px) {
    #newpassleftsub {
        width: 90%;
    }
}
