@keyframes slideDown {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .popup {
    animation: slideDown 0.3s ease-in-out;
    opacity: 1;
    transform: translateY(0);
  }
  
  .hidden {
    opacity: 0;
    transform: translateY(-20px);
    display: none;
  }
  .white-image {
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%);
  }