
@import "~react-big-calendar/lib/css/react-big-calendar.css";



.rbc-event {
  padding: 0 !important;
}

.rbc-toolbar {
  display: none;
}

.rbc-month-view {
  border: none;
}

.rbc-date-cell {
  font-weight: bold;
  color: #4a5568;
}
/* Hide scrollbar for WebKit browsers */
.custom-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for Firefox */
.custom-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
